import React from 'react';
import styled from 'styled-components';
import { ClearIcon } from '../Icons/Icons';
import { OutsideAlerter } from '../hooks/OutsideClick';

const ModalWrapper = styled.div`
	z-index: 99999999 !important;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #00000070;

	${({ show }) => (show ? 'display: block;' : 'display: none;')}
`;

const ModalContainer = styled.section`
	border-radius: 4px;
	position: fixed;
	background: white;
	width: 932px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: scroll;

	@media (max-width: 930px) {
		width: 360px;
		overflow: auto;
		overflow-x: hidden;
	}

	::-webkit-scrollbar {
		display: none;
	}
`;

const ModalContentWrapper = styled.div`
height: 100%;
width: 100%;
position: relative;
}
`;

const CloseIconWrapper = styled.div`
	position: absolute;
	right: 40px;
	top: 30px;
	z-index: 1;
	opacity: 0.5;

	@media (max-width: 930px) {
		right: 26px;
		top: 26px;
		opacity: unset;
	}

	&:hover {
		cursor: pointer;
	}
`;

export const Modal = ({
	children,
	handleModalVisibility,
	show,
	onCloseAction,
}) => {
	if (!show) return null;
	return (
		<ModalWrapper show={show}>
			<ModalContainer>
				<ModalContentWrapper id="referral-modal">
					<OutsideAlerter
						targetId="referral-modal"
						outsideAction={() => {
							onCloseAction && onCloseAction();
							handleModalVisibility(false);
						}}
					>
						<CloseIconWrapper
							onClick={() => {
								onCloseAction && onCloseAction();
								handleModalVisibility(false);
							}}
						>
							<ClearIcon />
						</CloseIconWrapper>
						<>{children}</>
					</OutsideAlerter>
				</ModalContentWrapper>
			</ModalContainer>
		</ModalWrapper>
	);
};
