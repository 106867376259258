import React from 'react';
import styled from 'styled-components';
import { ErrorCircle } from '../Icons/Icons';

export const InputStyled = styled.input`
	height: 46px;
	padding: 0px 14px 0px 14px;
	gap: 8px;
	border-radius: 12px;
	border: 1px solid #717171;
	width: inherit;

	${({ isCopyEnabled, theme }) =>
		isCopyEnabled &&
		`
    color: ${theme.colors.primaryColor};
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    `}

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: inherit;
	box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
	background-image: none;
	background-clip: padding-box;

	font-weight: 400;
	font-size: 16px;
	font-family: Noto Sans;
	text-align: left;

	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

	&:focus {
		box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
		background-color: #fff;
		outline: 0;
	}
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}
	&::placeholder {
		font-weight: 400;
		color: #717171;
		font-size: 16px;
	}

	&:disabled,
	&[readonly] {
		opacity: 1;
	}
	&:disabled {
		cursor: not-allowed;
	}
`;

const StyledFormGroup = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	width: inherit;
	${InputStyled} {
		${({ hasDanger }) =>
			hasDanger &&
			`
            border-color: #FE6050;
						;
        `}
	}
`;

const ErrorStyled = styled.div`
	margin-top: 8px;
	font-family: Noto Sans;
	font-size: 14px;
	font-weight: 400;
	text-align: left;
	color: #fe6050;
`;

const RightIcon = styled.div`
	background: #fff;
	position: absolute;
	top: 3px;
	right: 10px;
	width: 24px;
	height: 41px;
`;

const RightIconCentering = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const ErrorText = ({ text }) => {
	if (!text) {
		return null;
	} else {
		return <ErrorStyled>{text}</ErrorStyled>;
	}
};

export const Input = ({
	required,
	placeholder,
	value,
	onChange,
	errorText,
	customIcon,
	defaultValue,
	readOnly,
	isCopyEnabled,
}) => {
	return (
		<StyledFormGroup hasDanger={!!errorText}>
			{!!errorText && (
				<RightIcon>
					<RightIconCentering>
						<ErrorCircle />
					</RightIconCentering>
				</RightIcon>
			)}
			{customIcon && (
				<RightIcon>
					<RightIconCentering>{customIcon} </RightIconCentering>
				</RightIcon>
			)}
			<InputStyled
				required={required}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				errorText={errorText}
				defaultValue={defaultValue}
				readOnly={readOnly}
				isCopyEnabled={isCopyEnabled}
			/>
			<ErrorText text={errorText} />
		</StyledFormGroup>
	);
};
