import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Text = styled.div`
	display: inline;
	font-family: Noto Sans;
	font-size: 14px;
	font-weight: 400;
	text-decoration: underline;
	color: ${({ theme }) => theme.colors.primaryColor};

	&:hover {
		cursor: pointer;
	}
`;

const TextFooter = styled.span`
	font-family: Noto Sans;
	font-size: 12px;
	font-weight: 400;
	text-decoration: underline;
	color: ${({ theme }) => theme.colors.primaryColor};

	&:hover {
		cursor: pointer;
	}
`;

const Wrapper = styled.span`
	display: inline;
	z-index: 5;
`;

const Asteriks = styled.span`
	display: inline;
	color: #fe6050;
	font-family: Noto Sans;
	font-size: 12px;
	font-weight: 400;
	margin-left: 2px;

	&:hover {
		cursor: pointer;
	}
`;

export const TermsAndConditions = ({ link }) => {
	return (
		<Wrapper>
			<Text
				onClick={() => {
					window.open(link, '_blank').focus();
				}}
			>
				<FormattedMessage id="referral.terms.and.conditions.title" />
			</Text>
			<Asteriks>*</Asteriks>
		</Wrapper>
	);
};

export const TermsAndConditionsFooter = ({ link }) => {
	return (
		<TextFooter
			onClick={() => {
				window.open(link, '_blank').focus();
			}}
		>
			<FormattedMessage id="referral.terms.and.conditions.title" />
		</TextFooter>
	);
};
