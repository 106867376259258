import React, { useState } from 'react';
import styled from 'styled-components';
import { ROUTES } from '../constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { TermsAndConditions } from './TermsAndConditions';
import { Checkbox } from '../../Components/CheckboxLennuabi';
import { Button } from '../../Components/ButtonLennuabi';
import { ContentWrapper, ImageWrapper } from '../LennuabiModal';
import { ArrowRight } from '../../Icons/Icons';

const FormTitleValue = styled.span`
	color: #fabe00;
`;

const Footer = styled.div`
	height: 48px;
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 12px;
	font-weight: 450;
	line-height: 17.76px;
	color: #002864;

	svg {
		margin-left: 9px;
	}
`;

const FormTitle = styled.h2`
	margin-bottom: unset;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 40px;
	font-weight: 500;
	line-height: 52.8px;
	margin-top: 131.5px;
	color: #002864;

	@media (max-width: 930px) {
		margin-top: 32px;
		font-size: 36px;
		line-height: 43.2px;
	}
`;

const Content = styled.div`
	padding: 0px 53px 0px 40px;
	width: 492px;
	display: flex;
	flex-direction: column;
	overflow: scroll;

	@media (max-width: 930px) {
		margin: 0px 19.5px 0px 19.5px;
		width: unset;
		padding: unset;
		overflow-x: hidden;
	}
`;

const Line = styled.div`
	height: 1px;
	border-bottom: 1px solid #e5eaf3;

	margin: 24px -53px 0 -40px;
`;

const ContentWrapperInternal = styled.div`
	flex-grow: 1;

	@media (max-width: 930px) {
		margin-bottom: 44px;
	}
`;

const CheckboxWrapper = styled.div`
	margin-bottom: 16px;
`;

const Description = styled.div`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 19.6px;
	letter-spacing: 0.01em;
	color: #002864;
	margin: 24px 0 32px 0;
`;

const FooterContent = styled.div`
	&:hover {
		cursor: pointer;
	}

	@media (max-width: 930px) {
		font-size: 14px;
	}
`;

export const FriendSharedLink = ({ claimRedirectUrl, handleNextStep }) => {
	const intl = useIntl();

	const [checkbox, setCheckbox] = useState(false);
	const [isCheckboxMarked, setIsCheckboxMarked] = useState(true);

	const handleCheckboxChange = () => {
		if (!checkbox) {
			setIsCheckboxMarked(true);
		}
		setCheckbox(!checkbox);
	};

	const handleClaimNow = () => {
		if (!checkbox) {
			setIsCheckboxMarked(false);
			return;
		}

		window.location.href = claimRedirectUrl;
	};

	return (
		<ContentWrapper height="640px">
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/lennuabiMobile.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/lennuabiDesktop.jpg"
					/>
					<img src="https://ref.skycop.com/lennuabiDesktop.jpg" />
				</picture>
			</ImageWrapper>
			<Content>
				<ContentWrapperInternal>
					<FormTitle>
						<FormattedMessage
							id="referral.friend.view.title"
							values={{
								reward: (
									<FormTitleValue>
										<FormattedMessage id="referral.lennuabi.friend.view.title.reward" />
									</FormTitleValue>
								),
							}}
						/>
					</FormTitle>
					<Description>
						<FormattedMessage id="referral.lennuabi.friend.view.description" />
					</Description>
					<CheckboxWrapper>
						<Checkbox
							id="terms&conditions"
							action={
								<TermsAndConditions
									link={intl.formatMessage({
										id: 'referral.lennuabi.term.and.condition.link2',
									})}
								/>
							}
							label={intl.formatMessage({
								id: 'referral.lennuabi.terms.agreement.title',
							})}
							checked={checkbox}
							onChange={handleCheckboxChange}
							error={!isCheckboxMarked}
						/>
					</CheckboxWrapper>
					<Button onClick={() => handleClaimNow()}>
						<FormattedMessage id="referral.lennuabi.claim.now" />
					</Button>
				</ContentWrapperInternal>
				<Line />
				<Footer>
					<FooterContent onClick={() => handleNextStep(ROUTES.HOW_IT_WORKS)}>
						<FormattedMessage id="referral.lennuabi.how.it.works.link.title" />
						<ArrowRight />
					</FooterContent>
				</Footer>
			</Content>
		</ContentWrapper>
	);
};
