import React from 'react';
import styled from 'styled-components';
import { ErrorCircle } from '../Icons/Icons';

export const InputStyled = styled.input`
	height: 52px;
	padding: 16px 14px;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid #E5EBF3;
	background: #FFFFFF

	font-family: "CircularStd-Book", sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 19.6px;
	color: #002864;

		${({ isCopyEnabled }) =>
			isCopyEnabled &&
			`
   	color: #002864;
    font-family: "CircularStd-Book", sans-serif;
    font-size: 14px;
    font-weight: 450;
    cursor: pointer;
    `}

	&:focus {
		background-color: #fff;
		color: #002864;
		border-color: #002864;;

	}

	&::placeholder {
		font-family: "CircularStd-Book", sans-serif;
		font-size: 14px;
		font-weight: 450;
		line-height: 19.6px;
		text-align: left;
		color: #002864;
		opacity: 0.5;
	}

	&:disabled,
	&[readonly] {
	color: rgba(0, 40, 100, .5)

	}
	&:disabled {
		cursor: not-allowed;
		color: #002864;
	}
`;

const StyledFormGroup = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	width: inherit;
	${InputStyled} {
		${({ hasDanger }) =>
			hasDanger &&
			`
            border-color: #FE6050;
						;
        `}
	}
`;

const ErrorStyled = styled.div`
	margin-top: 4px;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 20.72px;
	text-align: left;
	color: #fe6050;
`;

const RightIcon = styled.div`
	background: #fff;
	position: absolute;
	top: 14px
	right: 14px;
	width: 24px;

	svg { 
		cursor: pointer;
	}
`;

const RightIconCentering = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const ErrorText = ({ text }) => {
	if (!text) {
		return null;
	} else {
		return <ErrorStyled>{text}</ErrorStyled>;
	}
};

export const Input = ({
	required,
	placeholder,
	value,
	onChange,
	errorText,
	customIcon,
	defaultValue,
	readOnly,
	isCopyEnabled,
}) => {
	return (
		<StyledFormGroup hasDanger={!!errorText}>
			{!!errorText && (
				<RightIcon>
					<RightIconCentering>
						<ErrorCircle width={24} />
					</RightIconCentering>
				</RightIcon>
			)}
			{customIcon && (
				<RightIcon>
					<RightIconCentering>{customIcon} </RightIconCentering>
				</RightIcon>
			)}
			<InputStyled
				required={required}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				errorText={errorText}
				defaultValue={defaultValue}
				readOnly={readOnly}
				isCopyEnabled={isCopyEnabled}
			/>
			<ErrorText text={errorText} />
		</StyledFormGroup>
	);
};
