import React, { useState } from 'react';
import styled from 'styled-components';
import { ROUTES } from '../constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { TermsAndConditions } from './TermsAndConditions';
import { Checkbox } from '../../Components/Checkbox';
import { Button } from '../../Components/Button';
import {
	FormWrapper,
	FormContentWrapper,
	Footer,
	FormContent,
	HelpWrapper,
	HelpText,
	ContentWrapper,
	ImageWrapper,
	FormTitleValue,
} from '../ReferralModal';
import { HelpIcon } from '../../Icons/Icons';
import { useSelector } from 'react-redux';

const ButtonWrapper = styled.div`
	width: 100%;

	@media (max-width: 930px) {
		margin-bottom: 10px;
	}
`;

const FormTitle = styled.h2`
	color: #000000;
	font-family: Noto Sans;
	font-size: 36px;
	font-weight: 600;

	margin-top: unset;
	margin-bottom: unset;

	margin-top: unset;
	margin-bottom: unset;

	@media (max-width: 930px) {
		line-height: 39.6px;
		font-size: 30px;
		line-height: 32px;
	}
`;

export const FriendSharedLink = ({ claimRedirectUrl, handleNextStep }) => {
	const intl = useIntl();
	const primaryColor = useSelector(
		(state) => state.modalType.theme.colors.primaryColor
	);

	const [checkbox, setCheckbox] = useState(false);
	const [isCheckboxMarked, setIsCheckboxMarked] = useState(true);

	const handleCheckboxChange = () => {
		if (!checkbox) {
			setIsCheckboxMarked(true);
		}
		setCheckbox(!checkbox);
	};

	const handleClaimNow = () => {
		if (!checkbox) {
			setIsCheckboxMarked(false);
			return;
		}

		window.location.href = claimRedirectUrl;
	};

	return (
		<ContentWrapper height="700px">
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/img/mobileImage.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/img/image.jpg"
					/>
					<img src="https://ref.skycop.com/img/image.jpg" />
				</picture>
			</ImageWrapper>
			<FormWrapper>
				<FormContentWrapper>
					<FormTitle>
						<FormattedMessage
							id="referral.friend.view.title"
							values={{
								reward: (
									<FormTitleValue>
										<FormattedMessage id="referral.friend.view.title.reward" />
									</FormTitleValue>
								),
							}}
						/>
					</FormTitle>
					<br />
					<FormContent>
						<FormattedMessage id="referral.friend.view.description" />
					</FormContent>

					<Checkbox
						id="terms&conditions"
						action={
							<TermsAndConditions
								link={intl.formatMessage({
									id: 'referral.term.and.condition.link2',
								})}
							/>
						}
						label={intl.formatMessage({
							id: 'referral.terms.agreement.title',
						})}
						checked={checkbox}
						onChange={handleCheckboxChange}
						error={!isCheckboxMarked}
					/>
					<br />
					<ButtonWrapper>
						<Button onClick={() => handleClaimNow()}>
							<FormattedMessage id="referral.claim.now" />
						</Button>
					</ButtonWrapper>
				</FormContentWrapper>
				<Footer>
					<HelpWrapper onClick={() => handleNextStep(ROUTES.HOW_IT_WORKS)}>
						<HelpIcon color={primaryColor} />
						<HelpText>
							<FormattedMessage id="referral.how.it.works.link.title" />
						</HelpText>
					</HelpWrapper>
				</Footer>
			</FormWrapper>
		</ContentWrapper>
	);
};
