import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { ReferralModal } from './src/ReferralModal';
import { axiosInstance } from './src/Configs/axios';
import { API_Routes } from './src/Configs/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setModalType, setTheme } from './src/ModalType/modalSlice';
import { ModalType } from './src/ModalType/constants';
import { LennuabiModal } from './src/LennuabiModal';

import { defaultTheme } from './src/theme/theme';
import { ThemeProvider } from 'styled-components';

export const App = ({
	locale,
	onGetReferralClick,
	claimRedirectUrl,
	shareCode,
	onCloseAction,
	modalType,
	theme,
}) => {
	const [messages, setMessages] = useState([]);
	const dispatch = useDispatch();
	const modalTypeValue = useSelector((state) => state.modalType);
	useEffect(() => {
		dispatch(setModalType(modalType));
		dispatch(setTheme(theme || defaultTheme));
	}, []);
	useEffect(() => {
		axiosInstance
			.get(`${API_Routes.TRANSLATION}/${locale}`)
			.then(({ data }) => {
				setMessages(data);
			})
			.catch((e) => {
				setMessages([]);
				console.error(e);
			});
	}, []);

	if (!messages || messages.length === 0) return null;

	return (
		<ThemeProvider theme={theme || defaultTheme}>
			<IntlProvider
				messages={messages}
				locale={locale || 'en'}
				defaultLocale="en"
			>
				{modalTypeValue.modalType === ModalType.Default ? (
					<ReferralModal
						shareCode={shareCode}
						onGetReferralClick={onGetReferralClick}
						claimRedirectUrl={claimRedirectUrl}
						onCloseAction={onCloseAction}
					/>
				) : (
					<LennuabiModal
						shareCode={shareCode}
						onGetReferralClick={onGetReferralClick}
						claimRedirectUrl={claimRedirectUrl}
						onCloseAction={onCloseAction}
					/>
				)}
			</IntlProvider>
		</ThemeProvider>
	);
};
