import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from '../../Components/CheckboxLennuabi';
import { Input } from '../../Components/InputLennuabi';
import { ArrowRight } from '../../Icons/Icons';
import { Button } from '../../Components/ButtonLennuabi';
import { TermsAndConditions } from './TermsAndConditions';
import { ContentWrapper, ImageWrapper } from '../LennuabiModal';
import { ROUTES } from '../constants';

export const ButtonWrapper = styled.div`
	width: 100%;

	@media (max-width: 930px) {
		margin-bottom: 40px;
	}
`;

const Content = styled.div`
	padding: 0px 53px 0px 40px;
	width: 492px;
	display: flex;
	flex-direction: column;
	overflow: scroll;

	@media (max-width: 930px) {
		margin: 0px 19.5px 0px 19.5px;
		width: unset;
		padding: unset;
		overflow-x: hidden;
	}
`;

const FormTitle = styled.h2`
	margin-bottom: unset;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 44px;
	font-weight: 500;
	line-height: 52.8px;
	// margin-top: 114px;
	margin-top: 0px;
	color: #002864;

	@media (max-width: 930px) {
		margin-top: 32px;
		font-size: 36px;
		line-height: 43.2px;
	}
`;

const FormTitleValue = styled.span`
	color: #fabe00;
`;

const Description1 = styled.div`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 19.6px;
	letter-spacing: 0.01em;
	color: #002864;
	margin-top: 24px;
`;
const Description2 = styled(Description1)`
	margin-top: 16px;
`;

const InputTitle = styled.div`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 23.68px;
	text-align: left;
	color: #002864;
	margin: 44px 0px 16px 0px;

	@media (max-width: 930px) {
		margin: 32px 0px 16px 0px;
	}
`;

const CheckboxWrapper = styled.div`
	margin: 24px 0px 16px 0px;
`;

const Footer = styled.div`
	min-height: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 12px;
	font-weight: 450;
	line-height: 17.76px;
	color: #002864;

	svg {
		margin-left: 9px;
	}
`;

const ContentWrapperInternal = styled.div`
	flex-grow: 1;
	align-content: center;
	margin-top: 24px;
	@media (max-width: 930px) {
		margin-bottom: 44px;
	}
`;

const Line = styled.div`
	height: 1px;
	border-bottom: 1px solid #e5eaf3;

	margin: 24px -53px 0 -40px;
`;

const FooterContent = styled.div`
	&:hover {
		cursor: pointer;
	}

	@media (max-width: 930px) {
		font-size: 14px;
	}
`;

export const EmailInputView = ({
	email,
	handleEmailChange,
	isChecked,
	handleTermsCheckbox,
	error,
	handleEmailSubmit,
	isCheckboxMarked,
	handleNextStep,
}) => {
	const intl = useIntl();

	return (
		<ContentWrapper height="640px">
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/lennuabiMobile.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/lennuabiDesktop.jpg"
					/>
					<img src="https://ref.skycop.com/lennuabiDesktop.jpg" />
				</picture>
			</ImageWrapper>
			<Content>
				<ContentWrapperInternal>
					<FormTitle>
						<FormattedMessage
							id="referral.default.view.title"
							values={{
								amount: (
									<FormTitleValue>
										<FormattedMessage id="referral.default.view.title.amount" />
									</FormTitleValue>
								),
							}}
						/>
					</FormTitle>
					<Description1>
						<div
							dangerouslySetInnerHTML={{
								__html: intl.formatMessage({
									id: 'referral.lennuabi.default.view.description1',
								}),
							}}
						/>
					</Description1>
					<Description2>
						<FormattedMessage id="referral.lennuabi.default.view.description2" />
					</Description2>
					<InputTitle>
						<FormattedMessage id="referral.lennuabi.email.input.title" />
					</InputTitle>
					<Input
						required
						placeholder={intl.formatMessage({
							id: 'referral.lennuabi.email.input.placeholder',
						})}
						value={email}
						onChange={({ target }) => handleEmailChange(target.value)}
						errorText={error}
					/>
					<CheckboxWrapper>
						<Checkbox
							id="terms&conditions"
							label={intl.formatMessage({
								id: 'referral.lennuabi.terms.agreement.title',
							})}
							checked={isChecked}
							onChange={handleTermsCheckbox}
							error={!isCheckboxMarked}
							action={
								<TermsAndConditions
									link={intl.formatMessage({
										id: 'referral.lennuabi.term.and.condition.link2',
									})}
								/>
							}
						/>
					</CheckboxWrapper>
					<Button onClick={() => handleEmailSubmit()}>
						<FormattedMessage id="referral.submit.button.title" />
					</Button>
				</ContentWrapperInternal>
				<Line />

				<Footer>
					<FooterContent onClick={() => handleNextStep(ROUTES.HOW_IT_WORKS)}>
						<FormattedMessage id="referral.lennuabi.how.it.works.link.title" />
						<ArrowRight />
					</FooterContent>
				</Footer>
			</Content>
		</ContentWrapper>
	);
};
