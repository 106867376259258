import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Text = styled.div`
	display: inline;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 20.72px;
	letter-spacing: 0.01em;

	color: #002864;

	&:hover {
		cursor: pointer;
	}
`;

const ActionText = styled.span`
	text-decoration: underline;
`;

const TextFooter = styled.span`
	display: inline-block;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 12px;
	font-weight: 450;
	line-height: 17.76px;
	text-align: left;
	color: #002864;
	text-decoration: underline;

	@media (max-width: 930px) {
		font-size: 14px;
	}

	&:hover {
		cursor: pointer;
	}
`;

const Wrapper = styled.span`
	display: inline-block;
	z-index: 5;
`;

const Asteriks = styled.span`
	display: inline;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 20.72px;
	letter-spacing: 0.01em;
	text-align: left;
	color: #002864;
	margin-left: 2px;
	&:hover {
		cursor: pointer;
	}
`;

const TextWrap = styled.div`
	width: fit-content;
	text-wrap: nowrap;
`;

export const TermsAndConditions = ({ link }) => {
	return (
		<Wrapper>
			<Text
				onClick={() => {
					window.open(link, '_blank').focus();
				}}
			>
				<TextWrap>
					<ActionText>
						<FormattedMessage id="referral.lennuabi.terms.and.conditions.title" />
					</ActionText>
					<Asteriks>*</Asteriks>
				</TextWrap>
			</Text>
		</Wrapper>
	);
};

export const TermsAndConditionsFooter = ({ link }) => {
	return (
		<TextFooter
			onClick={() => {
				window.open(link, '_blank').focus();
			}}
		>
			<FormattedMessage id="referral.lennuabi.terms.and.conditions.title" />
		</TextFooter>
	);
};
