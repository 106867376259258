import React from 'react';
import styled from 'styled-components';
import { Copy, ArrowRight } from '../../Icons/Icons';
import { ROUTES } from '../constants';
import { FormattedMessage } from 'react-intl';

import { ContentWrapper, ImageWrapper } from '../LennuabiModal';
import { Input } from '../../Components/InputLennuabi';
import { CheckedCircle } from '../../Icons/Icons';

const FormTitleValue = styled.span`
	color: #fabe00;
`;

const CopiedText = styled.span`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 16px;
	font-weight: 450;
	line-height: 22.4px;
	text-align: left;
	color: #fabe00;
	margin-left: 9px;
`;

const CopiedTextContent = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	margin-top: 16px;
`;

const QrImageWrapper = styled.div`
	${({ qrCode }) =>
		!qrCode
			? 'display: none;'
			: `
`}
	@media (max-width: 930px) {
		background: #fff;
		width: 241px;
		height: 253px;
		border-radius: 28px;
		border: 1px solid;
		border-image-source: linear-gradient(
			180deg,
			rgba(224, 224, 224, 0) 0%,
			#e0e0e0 100%
		);
		position: absolute;
		top: 43px;
		left: 61px;
	}
`;

const QrCodeImg = styled.div`
	${({ qrCode }) => (!qrCode ? 'display: none;' : '')}
	@media (max-width: 930px) {
		background-image: ${({ qrCode }) => qrCode && `url(${qrCode})`};
		background-size: 158px 158px;
		background-repeat: no-repeat;
		position: absolute;
		top: 47.5px;
		left: 42.5px;
		width: 100%;
		height: 100%;
	}
`;

const Content = styled.div`
	padding: 0px 53px 0px 40px;
	width: 492px;
	display: flex;
	flex-direction: column;
	overflow: scroll;

	@media (max-width: 930px) {
		margin: 0px 19.5px 0px 19.5px;
		width: unset;
		padding: unset;
		overflow-x: hidden;
	}
`;

const FormTitle = styled.h2`
	margin-top: unset;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 44px;
	font-weight: 500;
	line-height: 52.8px;
	color: #002864;
	margin-bottom: 54px;

	@media (max-width: 930px) {
		margin-top: 54px;
		margin-bottom: 32px;
		font-size: 36px;
		line-height: 43.2px;
	}
`;

const Line = styled.div`
	height: 1px;
	border-bottom: 1px solid #e5eaf3;

	margin: 24px -53px 0 -40px;
`;

const ContentWrapperInternal = styled.div`
	flex-grow: 1;
	align-content: center;

	@media (max-width: 930px) {
		margin-bottom: 82px;
	}
`;

const Footer = styled.div`
	margin-top: unset;
	height: 48px;
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 12px;
	font-weight: 450;
	line-height: 17.76px;
	color: #002864;

	svg {
		margin-left: 9px;
	}
`;

const InputTitle = styled.div`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 23.68px;
	text-align: left;
	color: #002864;
	margin-bottom: 16px;

	@media (max-width: 930px) {
		margin: 32px 0px 16px 0px;
	}
`;

const FooterContent = styled.div`
	&:hover {
		cursor: pointer;
	}

	@media (max-width: 930px) {
		font-size: 14px;
	}
`;

export const ShareLinkView = ({
	shareLink,
	showCopiedLinkInfo,
	handleLinkCopy,
	handleNextStep,
}) => {
	const breakpoint = 930;
	const width = window.innerWidth;

	return (
		<ContentWrapper height="640px">
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/mobileQr.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/lennuabiDesktop.jpg"
					/>
					<img src="https://ref.skycop.com/lennuabiDesktop.jpg" />
				</picture>
				<QrImageWrapper qrCode={shareLink.qrLink}>
					<QrCodeImg id="qrlogo" qrCode={shareLink.qrLink} />
				</QrImageWrapper>
			</ImageWrapper>
			<Content>
				<ContentWrapperInternal>
					<FormTitle>
						<FormattedMessage
							id="referral.lennuabi.default.view.title"
							values={{
								amount: (
									<FormTitleValue>
										<FormattedMessage id="referral.lennuabi.default.view.title.amount" />
									</FormTitleValue>
								),
							}}
						/>
					</FormTitle>
					<InputTitle>
						{width < breakpoint ? (
							<FormattedMessage id="referral.lennuabi.share.link.description.mobile" />
						) : (
							<FormattedMessage id="referral.lennuabi.share.link.description.desktop" />
						)}
					</InputTitle>
					<Input
						defaultValue={shareLink.link}
						customIcon={
							<span onClick={() => handleLinkCopy(shareLink.link)}>
								<Copy />
							</span>
						}
						readOnly={true}
						isCopyEnabled={true}
					/>
					{showCopiedLinkInfo && (
						<CopiedTextContent>
							<CheckedCircle color="#FABE00" width={24} />
							<CopiedText>
								<FormattedMessage id="referral.lennuabi.share.link.copied" />
							</CopiedText>
						</CopiedTextContent>
					)}
				</ContentWrapperInternal>
				<Line />
				<Footer>
					<FooterContent onClick={() => handleNextStep(ROUTES.HOW_IT_WORKS)}>
						<FormattedMessage id="referral.lennuabi.how.it.works.link.title" />
						<ArrowRight />
					</FooterContent>
				</Footer>
			</Content>
		</ContentWrapper>
	);
};
