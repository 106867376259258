import React from 'react';
import styled, { css } from 'styled-components';
import { grey, blueviolet } from 'color-name';
import { CheckMark } from '../Icons/Icons';

const Label = styled.label`
	position: relative;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	margin: 8px 0px 8px 16px;
	display: inline;
	padding-right: 2px;
`;

const CheckboxLabel = styled.span`
	margin-left: -6px;
	font-family: Noto Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 20.72px;
	text-align: left;
	padding-right: 4px;
`;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
`;

const EnabledStyledLabel = css`
	color: ${(props) => (props.disabled ? grey : blueviolet)};
	> a {
		color: pink;
		text-decoration: underline;
	}
`;

const DisabledStyledLabel = css`
	color: darkgray;
	> a {
		color: darkgray;
		text-decoration: underline;
	}
`;

const StyledLabel = styled.label`
	display: inline;
	margin: 0 0 0 8px;
	${(props) => (props.disabled ? DisabledStyledLabel : EnabledStyledLabel)};
	&:hover {
		cursor: pointer;
	}
`;

const Icon = styled.div`
	position: absolute;
	fill: black;
	width: 16px;
	height: 16px;
	left: 1px;
`;

const HiddenCheckbox = styled.input`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const checkedStyles = css`
	border: 1px solid #333333;

	${Icon} {
		visibility: visible;
	}
`;

const disabeldStyles = `
  border: 2px solid darkgray;
  background: darkgray;

  ${Icon} {
    visibility: hidden;
  }
`;

const enableCheckboxStyles = css`
	background: white;
	border: ${(props) =>
		props.error ? '1px solid #FE6050;' : ' 1px solid #333333;'};

	${HiddenCheckbox}:focus & {
		border-color: pink;
	}
`;

const uncheckedStyles = css`
	${(props) => (props.disabled ? disabeldStyles : enableCheckboxStyles)};

	${Icon} {
		visibility: hidden;
	}
`;

const StyledCheckbox = styled.div`
	position: relative;
	height: 16px;
	width: 16px;
	border-radius: 4px;
	${(props) => (props.checked ? checkedStyles : uncheckedStyles)};
`;

const LabelWrapper = styled.div`
	margin-left: 6px;
`;

const CheckboxWrapper = styled.div`
	width: 16px;
	display: flex;
	margin-top: 2px;

	&:hover {
		cursor: pointer;
	}
`;

export const Checkbox = ({
	checked,
	onChange,
	id,
	label,
	disabled,
	error,
	action,
}) => {
	return (
		<Wrapper>
			<CheckboxWrapper>
				<HiddenCheckbox
					checked={checked}
					onChange={onChange}
					type="checkbox"
					id={`checkbox_${id}`}
				/>
				<StyledCheckbox
					error={error}
					checked={checked}
					disabled={disabled}
					onClick={onChange}
				>
					<Icon>
						<CheckMark />
					</Icon>
				</StyledCheckbox>
			</CheckboxWrapper>
			<LabelWrapper>
				<StyledLabel htmlFor={`checkbox_${id}`} disabled={disabled}>
					<CheckboxLabel>{label}</CheckboxLabel>
				</StyledLabel>
				{action}
			</LabelWrapper>
		</Wrapper>
	);
};
