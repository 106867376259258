import React, { useState } from 'react';
import styled from 'styled-components';

const VerticalStepper = styled.div`
	padding-bottom: 20px;
`;

const StepInnerLine = styled.div`
	position: absolute;
	width: 0px;
	z-index: 1;
	border-right: 1px dashed ${({ theme }) => theme.colors.primaryColor};
	transform: translateY(-100%);
	height: ${({ isFirst, line }) => (isFirst ? line : line + 3.5)}px;
`;

const StepInnerCircle = styled.div`
	display: block;
	height: 7px;
	width: 7px;
	background: ${({ theme }) => theme.colors.primaryColor};
	border-radius: 50%;
	z-index: 2;
`;

const StepInner = styled.div`
	display: flex;
	justify-content: center;
	padding: 0px 20px;
`;

const Step = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	position: relative;
	padding-top: 20px;
	font-size: 14px;
	color: #95989a;

	display: flex;
	align-items: center;
`;

const StepLeftContent = styled.div`
	font-family: Noto Sans;
	font-size: 32px;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.primaryColor};
`;

const StepRightContent = styled.div`
	font-family: Noto Sans;
	font-size: 16px;
	font-weight: 600;
	line-height: 22.4px;
	text-align: left;
	color: #000000;
`;

let lineHeight = [];
export const Stepper = ({ steps, completeColor }) => {
	const [bigger, setBigger] = useState(0);

	function verifyWidth(node) {
		let auxBigger = 0;
		lineHeight = [];
		if (node && node.children.length > 1) {
			Array.from(node.children).forEach((child) => {
				const grandChild = child.children[0];
				lineHeight.push(child.children[2].offsetHeight);
				if (grandChild.offsetWidth > auxBigger) {
					auxBigger = grandChild.offsetWidth;
				}
			});
			setBigger(auxBigger);
		}
	}

	function getMinWidth() {
		if (bigger > 0) {
			return `${bigger}px`;
		}
		return null;
	}

	return (
		<VerticalStepper ref={verifyWidth}>
			{steps &&
				steps.map((step, index) => {
					const line = lineHeight[index] / 2 + lineHeight[index - 1] / 2 + 20;
					return (
						<Step key={`step-${step.key || index}`}>
							<StepLeftContent
								style={{
									minWidth: getMinWidth(),
								}}
							>
								{step.leftContent}
							</StepLeftContent>
							<StepInner>
								<StepInnerCircle />
								<StepInnerLine isFirst={index === 1} line={line} />
							</StepInner>
							<StepRightContent>
								<div dangerouslySetInnerHTML={{ __html: step.rightContent }} />
							</StepRightContent>
						</Step>
					);
				})}
		</VerticalStepper>
	);
};
