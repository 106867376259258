import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
	width: 100%;
	font-family: Noto Sans;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.2px;
	text-align: center;
	background: ${({ theme }) => theme.colors.primaryColor};
	padding: 16px 32px 16px 32px;
	gap: 0px;
	border-radius: 16px;
	border-color: transparent;
	color: #fff;
	&:hover,
	&:active,
	&:focus {
		cursor: pointer;
		color: #ccc;
		outline: none;
	}
`;

export const Button = ({ children, onClick }) => (
	<ButtonStyled onClick={onClick}>{children}</ButtonStyled>
);
