import axios from 'axios';

export const axiosInstance = axios.create({
	baseURL: process.env.AXIOS_BASE_URL,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		timeout: 1000,
	},
});
