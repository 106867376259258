import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
	width: 100%;
	background-color: #fabe00;
	padding: 16px 32px;
	height: 52px;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 16px;
	font-weight: 450;
	line-height: 19.2px;
	text-align: center;
	color: #002864;
	border-radius: 4px;

	text-align: center;
	border-color: transparent;
	&:hover,
	&:active,
	&:focus {
		cursor: pointer;
		outline: none;
	}
`;

export const Button = ({ children, onClick }) => (
	<ButtonStyled onClick={onClick}>{children}</ButtonStyled>
);
