import React, { useRef, useEffect } from 'react';

const useOutsideAlerter = (ref, outsideAction) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				outsideAction();
			}
		};
		document.addEventListener('click', handleClickOutside, { capture: true });
		return () => {
			document.removeEventListener('click', handleClickOutside, {
				capture: true,
			});
		};
	}, [ref]);
};

export const OutsideAlerter = ({ children, outsideAction, targetId }) => {
	const wrapperRef = useRef(null);

	useOutsideAlerter(wrapperRef, outsideAction, targetId);

	return <div ref={wrapperRef}>{children}</div>;
};
