import { createSlice } from '@reduxjs/toolkit';
export const typeSlice = createSlice({
	name: 'modalType',
	initialState: {
		modalType: 'default',
		theme: null,
	},
	reducers: {
		setModalType: (state, action) => {
			state.modalType = action.payload;
		},
		setTheme: (state, action) => {
			state.theme = action.payload;
		},
	},
});
export const { setModalType, setTheme } = typeSlice.actions;
export default typeSlice.reducer;
